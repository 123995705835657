import api from './api';


const authService = {
    async login(username, password) {
        const response = await api.login(username, password);
        if (response.data.access_token) {
            localStorage.setItem('user', JSON.stringify(response.data));
        }
        return response.data;
    },

    logout() {
        localStorage.removeItem('user');
    },

    async register(username, email, password) {
        return await api.register(username, email, password);
    },

    getCurrentUser() {
        return JSON.parse(localStorage.getItem('user'));
    },

    updateUser(user) {
        localStorage.setItem('user', JSON.stringify(user));
    },

    setUserCurrentThread(threadId) {
        const user = this.getCurrentUser();
        user.current_thread = threadId;
        this.updateUser(user);
    },

    async validateToken() {
        let valid = false
        try {
            const response = await api.validateToken();
            valid = response.data.valid;
        } catch (error) {
            console.log(error);
        }

        if (!valid) {
            this.logout();
        }
        return valid;
    }
};

export default authService;
