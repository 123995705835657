import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';
import Welcome from './views/Welcome';
import Login from './views/Login';
import Register from './views/Register';
import Chat from './views/Chat';

function App() {
  return (
      <Router>
        <Routes>
          <Route path="/" element={<Welcome />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/chat" element={<PrivateRoute><Chat /></PrivateRoute>} />
          {/* Other routes */}
        </Routes>
      </Router>
  );
}

export default App;
