import React, {forwardRef} from 'react';
import './LoadingBar.css';

const LoadingBar = forwardRef((props, ref) => {
    return (
        <div ref={ref} className="loading-bar"></div>
    );
});

export default LoadingBar;
