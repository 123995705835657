import axios from 'axios';
import history from '../history';

const baseURL = process.env.REACT_APP_API_URL;

const apiBase = axios.create({
    baseURL: baseURL,
});


// Set up a request interceptor
apiBase.interceptors.request.use((config) => {
    // Get the token from localStorage
    const user = JSON.parse(localStorage.getItem('user'));

    // If a token is present, add it to the request's Authorization header
    if (user) {
        config.headers['Authorization'] = `Bearer ${user.access_token}`;
    }

    return config;
}, (error) => {
    return Promise.reject(error);
});

// Set up a response interceptor
apiBase.interceptors.response.use((response) => {
    // If the response is successful, just return it
    return response;
}, (error) => {
    // If the response status is 401, redirect to the login page
    if (error.response && error.response.status === 401) {
        history.push('/login');
        window.location.reload();
    }

    return error.response
});


const api = {
    register(username, email, password) {
        return apiBase.post('/auth/register', { username, email, password }, {headers: {}});
    },

    login(username, password) {
        return apiBase.post('/auth/login', { username, password }, {headers: {}});
    },

    validateToken() {
        return apiBase.get('/auth/validate_token');
    },

    // Assistant-related operations
    createAssistant(name, instructions, tools, model) {
        return apiBase.post('/chat/assistant', { name, instructions, tools, model });
    },

    manageAssistant(assistantId, method, data = {}) {
        return apiBase.request({
            url: `/chat/assistant/${assistantId}`,
            method: method,
            data: data
        });
    },

    // Thread-related operations
    createThread() {
        return apiBase.post('/chat/thread').then(res => res.data);
    },

    manageThread(threadId, method) {
        return apiBase.request({
            url: `/chat/thread/${threadId}`,
            method: method
        });
    },

    // Message-related operations
    createMessage(threadId, content) {
        return apiBase.post('/chat/message', { thread_id: threadId, content });
    },

    listMessages(threadId, limit = 20) {
        return apiBase.get(`/chat/message/${threadId}?limit=${limit}`).then(res => res.data);
    },

    // Run-related operations
    createRun(assistantId, threadId) {
        return apiBase.post('/chat/run', { assistant_id: assistantId, thread_id: threadId }).then(res => res.data);
    },

    retrieveRun(threadId, runId) {
        return apiBase.get(`/chat/run/${threadId}/${runId}`).then(res => res.data);
    },

    // Text to speech
    generateAudio(text, voice_id, voice_stability, voice_similarity_boost, voice_style, voice_use_speaker_boost) {
        // Axios is not designed to handle the response type 'stream',
        // so we use the native fetch API for streaming responses.
        // Axios would typically load the entire response into memory,
        // which is not efficient for streaming.
        // Get the token from localStorage
        const user = JSON.parse(localStorage.getItem('user'));

        if (!user) {
            throw new Error('User is not logged in.');
        }

        return fetch(`${baseURL}/tts/generate-audio`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.access_token}`
            },
            body: JSON.stringify({
                text,
                voice_id,
                voice_stability,
                voice_similarity_boost,
                voice_style,
                voice_use_speaker_boost
            })
        });
    },
}

export default api;
