import React, { useState } from 'react';

const ImageButton = ({ image, hoverImage, onClick }) => {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <button
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            className="image-button"
            onClick={onClick}
        >
            <img src={isHovered ? hoverImage : image} alt="" className="button-image" />
        </button>
    );
};

export default ImageButton;
