import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import authService from '../../services/authService';
import ImageButton from "../../components/ImageButton";
import './Welcome.css';

import loginImage from "../../assets/keyhole-100x100.webp";
import registerImage from "../../assets/sorting-hat-button-100x100.webp";

const Welcome = () => {
    const navigate = useNavigate();
    const user = authService.getCurrentUser();

    useEffect(() => {
        if (user) {
            // Redirect to the chat page if the user is already logged in
            navigate('/chat');
        }
    }, [user, navigate]); // Dependency array: only run effect if user or navigate changes

    const redirectToLogin = () => navigate('/login');
    const redirectToRegister = () => navigate('/register');

    if (user) {
        // Render nothing if the user is already logged in (redirection is handled by useEffect)
        return null;
    }

    return (
        <div className="welcome-page">
            <div className="welcome-container">
                <h1>Welcome to Hogwarts Carys</h1>
                <p>Please login or register to continue.</p>
                <div className="button-container">
                    <ImageButton image={loginImage} hoverImage={loginImage} onClick={redirectToLogin} />
                    <ImageButton image={registerImage} hoverImage={registerImage} onClick={redirectToRegister} />
                </div>
            </div>
        </div>
    );
};

export default Welcome;
