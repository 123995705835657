import React, { useState } from 'react';
import './TopBar.css';
import ImageButton from "../ImageButton";
import logoutImage from "../../assets/castle-doors/castle-doors-closed-100x100.webp";
import logoutHoverImage from "../../assets/castle-doors/castle-doors-open-100x100.webp";
import scrollImage from "../../assets/scroll-button-100x100.webp";
import { FaBars, FaCaretDown } from 'react-icons/fa'; // Import icons for menu and arrow

import authService from "../../services/authService";

function TopBar({user, onNewThreadClick, profiles, currentProfile, setProfile}) {
    const [isMenuOpen, setMenuOpen] = useState(false);
    const [isProfileDropdownOpen, setProfileDropdownOpen] = useState(false);

    const onLogout = () => {
        authService.logout();
        window.location.reload();
    };

    const toggleMenu = () => {
        setProfileDropdownOpen(false)
        setMenuOpen(!isMenuOpen);
    };

    const toggleProfileDropdown = () => {
        setMenuOpen(false)
        setProfileDropdownOpen(!isProfileDropdownOpen);
    };

    const onProfileClick = (profile) => {
        setProfile(profile);
        setProfileDropdownOpen(false);
    }

    return (
        <div className="top-bar">
            <div className="top-bar-title">
                <h1>Owl Post</h1>
            </div>
            <div className="top-bar-button-container">
                {currentProfile &&
                    <div className="profile-selector" onClick={toggleProfileDropdown}>
                        <img src={currentProfile.picture} alt="Profile" className="profile-badge" />
                        <FaCaretDown className="profile-arrow" />
                    </div>
                }
                {isProfileDropdownOpen && (
                    <div className="profile-dropdown">
                        {profiles.map(profile => (
                            <div key={profile.id} className="profile-item" onClick={() => onProfileClick(profile)}>
                                <img src={profile.picture} alt={profile.name} className="profile-badge" />
                                <span>{profile.name}</span>
                            </div>
                        ))}
                    </div>
                )}
                <div className="menu-icon" onClick={toggleMenu}>
                    <FaBars />
                </div>
                {isMenuOpen && (
                    <div className="dropdown-menu">
                        <ImageButton image={scrollImage} hoverImage={scrollImage} onClick={onNewThreadClick} />
                        <ImageButton image={logoutImage} hoverImage={logoutHoverImage} onClick={onLogout} />
                    </div>
                )}
            </div>
        </div>
    );
}

export default TopBar;