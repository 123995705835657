import React, { useState, useEffect, useRef } from 'react';
import api from '../../services/api';
import authService from "../../services/authService";
import './Chat.css';
import Message from "../../components/Message";
import TopBar from "../../components/TopBar";
import LoadingBar from "../../components/LoadingBar";
import AudioProgressBar from "../../components/AudioProgressBar";


function Chat() {
    const [messages, setMessages] = useState([]);
    const [messagesLimit, setMessagesLimit] = useState(20);
    const [input, setInput] = useState('');
    const [threadId, setThreadId] = useState(null);
    const [runInProgress, setRunInProgress] = useState(false);
    const [isAudioEnabled, setIsAudioEnabled] = useState(false);
    const [showScrollButton, setShowScrollButton] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [latestMessageId, setLatestMessageId] = useState(null);
    const [isAudioLoading, setIsAudioLoading] = useState(false);
    const [showAudioProgress, setShowAudioProgress] = useState(false);
    const [speakerSettings, setSpeakerSettings] = useState(null)
    const [speakers, setSpeakers] = useState([
        {
            id: 1,
            name: 'dumbledore',
            picture: 'https://metro.co.uk/wp-content/uploads/2022/11/SEI_134248093-d493.jpg?quality=90&strip=all&crop=20px%2C22px%2C1123px%2C590px&resize=1200%2C630',
            assistant_id: 'asst_VTUFpcgVntBPnjHN5NuGcOtY',
            voice_id: 'K4CxYfzkY7Uf4p2TJVeV',
            voice_stability: 0.53,
            voice_similarity_boost: 0.49,
            voice_style: 0.45,
            voice_use_speaker_boost: true
        }, {
            id: 2,
            name: 'mcgonagall',
            picture: 'https://images.immediate.co.uk/production/volatile/sites/3/2018/11/Dame-Maggie-Smith-1-ceebe73.jpg?quality=90&crop=741px,68px,1543px,1028px&resize=980,654',
            assistant_id: 'asst_5DAkyAZvqAyMHtlKd2vQkPsd',
            voice_id: 'vAIolUpDJna4tGPhhzVY',
            voice_stability: 0.5,
            voice_similarity_boost: 0.99,
            voice_style: 0.45,
            voice_use_speaker_boost: true
        }
    ])

    let assistantNameById = {}
    speakers.forEach(speaker => {
        assistantNameById[speaker.assistant_id] = speaker.name
    })

    const bottomChatRef = useRef(null);
    const chatFormRef = useRef(null);
    const loadingBarRef = useRef(null);

    let currentAudio = useRef(null); // Add this line to keep a reference to the currently playing audio

    useEffect(() => {
        if (speakerSettings !== null) {
            return;
        }

        setSpeakerSettings(speakers[0])
    }, [])

    useEffect(() => {
        if (threadId) {
            return;
        }
        const user = authService.getCurrentUser();
        if (user.current_thread) {
            setThreadId(user.current_thread);
        } else {
            api.createThread()
                .then(res => {
                    setThreadId(res.id);
                    authService.updateUser({ ...user, current_thread: res.id })
                })
                .catch(err => {
                    console.error('Error creating new thread:', err);
                });
        }
    }, [threadId]); // Empty array means this useEffect will run once after initial render

    const cancelAudio = () => {
        if (currentAudio.current) {
            currentAudio.current.pause(); // Stop the audio if it's currently playing
            currentAudio.current = null; // Reset the reference
        }

        setShowAudioProgress(false)
    }

    const toggleAudio = () => {
        setIsAudioEnabled(!isAudioEnabled);
        cancelAudio()
    };

    const playAudioForMessage = async (messageText) => {
        if (!isAudioEnabled || !messageText) {
            return;
        }

        setShowAudioProgress(true);

        try {
            messageText = messageText.replace(/Carys/gi, 'Careese');
            const audioResponse = await api.generateAudio(
                messageText,
                speakerSettings.voice_id,
                speakerSettings.voice_stability,
                speakerSettings.voice_similarity_boost,
                speakerSettings.voice_style,
                speakerSettings.voice_use_speaker_boost
            );

            if (audioResponse.ok) {
                const reader = audioResponse.body.getReader();
                const mediaSource = new MediaSource();
                setIsAudioLoading(true);
                currentAudio.current = new Audio();
                currentAudio.current.src = URL.createObjectURL(mediaSource);

                mediaSource.addEventListener('sourceopen', () => {
                    setIsAudioLoading(false);
                    const sourceBuffer = mediaSource.addSourceBuffer('audio/mpeg');

                    const appendData = async ({ done, value }) => {
                        if (done) {
                            // Wait for the source buffer to finish updating before calling endOfStream
                            if (sourceBuffer.updating) {
                                sourceBuffer.addEventListener('updateend', () => {
                                    if (!sourceBuffer.updating && mediaSource.readyState === 'open') {
                                        mediaSource.endOfStream();
                                    }
                                }, { once: true });
                            } else if (mediaSource.readyState === 'open') {
                                mediaSource.endOfStream();
                            }
                            return;
                        }

                        if (sourceBuffer.updating) {
                            await new Promise(resolve => setTimeout(resolve, 100));
                        }

                        try {
                            sourceBuffer.appendBuffer(value);
                        } catch (error) {
                            console.error('Buffer append error:', error);
                            // Handle buffer full or other buffer-related errors
                        }
                    };

                    const processData = async () => {
                        try {
                            const data = await reader.read();
                            await appendData(data);
                            if (!data.done) {
                                await processData();
                            }
                        } catch (error) {
                            console.error('Stream read error:', error);
                            // Handle network or stream-related errors
                        }
                    };

                    processData();

                    sourceBuffer.addEventListener('updateend', () => {
                        if (currentAudio.current && currentAudio.current.paused) {
                            currentAudio.current.play().catch(e => console.error("Error playing audio:", e));
                        }
                    });
                });
            } else {
                console.error('Audio generation failed', await audioResponse.text());
            }
        } catch (error) {
            console.error('Error playing the audio', error);
        }
    };

    const fetchMessages = async () => {
        if (!threadId) {
            return;
        }
        await api.listMessages(threadId, messagesLimit)
            .then(res => {
                setMessages(res.data)
            });
    }

    // Fetch messages when the component mounts
    useEffect(() => {
        fetchMessages();
    }, [threadId]);

    useEffect(() => {
        if (!messages.length) {
            return;
        }

        // Scroll to bottom whenever messages change or runInProgress changes
        if (messages[0].id !== latestMessageId) {
            scrollToBottom();
        }

        if (messages[0].id !== latestMessageId && messages[0].role === "assistant") {
            playAudioForMessage(messages[0].content[0].text.value)
        }

        if (messages[0].id !== latestMessageId) {
            setLatestMessageId(messages[0].id)
        }
    }, [messages, latestMessageId]);

    useEffect(() => {
        if (!runInProgress) {
            scrollToBottom()
        }
    }, [runInProgress])

    useEffect(() => {
        fetchMessages()
    }, [threadId])

    useEffect(() => {
        // This effect runs whenever the input or runInProgress changes and sets the height of the chat-form
        // and the bottom of the loading bar in case the height of the input bar changes or the loading bar is present

        // Calculate the height of the chat-form and set the loading bar's bottom to that value
        let chatMessagesPaddingBottom = 0;
        if (runInProgress && chatFormRef.current && loadingBarRef.current) {
            // Set the loading bar's bottom to the height of the chat-form
            const chatFormHeight = chatFormRef.current.offsetHeight;
            loadingBarRef.current.style.bottom = `${chatFormHeight}px`;

            // Calculate the padding bottom for the chat messages to account for the chat-form and loading bar
            chatMessagesPaddingBottom = chatFormHeight + loadingBarRef.current.offsetHeight;
        } else if (chatFormRef.current) {
            // Calculate the padding bottom for the chat messages to account for the chat-form only
            chatMessagesPaddingBottom = chatFormRef.current.offsetHeight;
        }

        // Set the padding bottom of the chat messages
        const chatMessagesElement = document.querySelector('.chat-messages');
        if (chatMessagesElement) {
            // Check if the scroll is at the bottom
            const bottom = chatMessagesElement.scrollHeight - chatMessagesElement.scrollTop <= chatMessagesElement.clientHeight * 1.5;

            // Update the padding
            chatMessagesElement.style.paddingBottom = `${chatMessagesPaddingBottom}px`;

            // Scroll to bottom only if scroll was already at the bottom
            if (bottom && runInProgress) {
                scrollToBottom()
            }
        }

    }, [input, runInProgress]); // Recalculate whenever the input (and therefore the chat-form's height) changes

    const handleScroll = (e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight * 1.5;
        if (bottom) {
            setShowScrollButton(false);
        } else {
            setShowScrollButton(true);
        }

        const top = e.target.scrollTop === 0;
        if (top) {
            setMessagesLimit(prevLimit => Math.min(prevLimit + 10, 100));
            fetchMessages();
        }
    };

    const scrollToBottom = () => {
        bottomChatRef.current.scrollIntoView({ behavior: "smooth" });
    };

    // Handle text input
    const handleInputChange = (event) => {
        setInput(event.target.value);

        // Calculate the number of line breaks
        const lineBreaks = event.target.value.split('\n').length;

        // Set the rows attribute to the calculated value or 1 if there's no line break
        event.target.rows = lineBreaks > 1 ? lineBreaks : 1;
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            handleSubmit(event);
        }
    };

    // Handle form submission
    const handleSubmit = async (event) => {
        if (speakerSettings === null) {
            return;
        }

        event.preventDefault();

        if (runInProgress) {
            return;
        }

        cancelAudio()

        setErrorMessage(null)

        await api.createMessage(threadId, input)
            .then(fetchMessages);

        setInput('');

        let run = await api.createRun(speakerSettings.assistant_id, threadId)

        setRunInProgress(true)

        // Wait for run to complete, polling every 1 second
        // queued, in_progress, requires_action, cancelling, cancelled, failed, completed, or expired.
        let runStatus = 'in_progress';
        while (runStatus !== 'completed') {
            await api.retrieveRun(threadId, run.id)
                .then(res => {
                    runStatus = res.status;
                    if (runStatus === 'failed') {
                        setErrorMessage(res.last_error.code)
                    }
                });

            if (runStatus === 'failed') {
                break
            }

            await new Promise(r => setTimeout(r, 1000));
        }

        setRunInProgress(false)
        await fetchMessages()
    };

    const handleNewThread = async () => {
        cancelAudio()

        await api.createThread()
            .then(res => {
                setThreadId(res.id)
                authService.setUserCurrentThread(res.id)
            })
    }

    return (
        <div className="chat-container">
            <TopBar onNewThreadClick={handleNewThread} currentProfile={speakerSettings} profiles={speakers} setProfile={setSpeakerSettings}/>
            <ul className="chat-messages" onScroll={handleScroll}>
                {messages.slice().reverse().map((message, index) => (
                    <li key={index} className={"message-li"}>
                        <div className="message-container">
                            <Message
                                sender={message.role === 'user' ? 'user' : assistantNameById[message.assistant_id]}
                                message={message}
                                audioProgress={index === messages.length - 1 && message.role === "assistant" && currentAudio.current && showAudioProgress && <AudioProgressBar audio={currentAudio.current} isLoading={isAudioLoading} onComplete={() => setShowAudioProgress(false)} numWords={message.content[0].text.value.trim().split(/\s+/).length}/>}
                            />
                        </div>
                    </li>
                ))}
                {errorMessage && <li key={-2}><div className="message-container"><p className="error">{errorMessage}</p></div></li>}
                <div ref={bottomChatRef}></div>
            </ul>
            {showScrollButton && <button onClick={scrollToBottom} className="scroll-button"/>}
            <div className={"input-container"}>
                {runInProgress && <LoadingBar ref={loadingBarRef}/>}
                <form ref={chatFormRef} onSubmit={handleSubmit} className="chat-form">
                    <button type="button" onClick={toggleAudio} className="mute-button">
                        {isAudioEnabled ? '🔊' : '🔇'}
                    </button>
                    <textarea value={input} onChange={handleInputChange} onKeyDown={handleKeyDown} rows={"1"}/>
                    <button className={`submit-button ${runInProgress ? 'fade-out' : 'fade-in'}`} type="submit"/>
                </form>
            </div>

        </div>
    );
}

export default Chat;
