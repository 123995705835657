import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import authService from '../../services/authService';
import './Login.css';
import ImageButton from "../../components/ImageButton";
import keyholeImage from "../../assets/keyhole-100x100.webp";


const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        setErrorMessage('');

        try {
            await authService.login(username, password);
            navigate('/chat'); // Redirect to chat after login
        } catch (error) {
            setErrorMessage('Failed to login. Please check your credentials.');
        }
    };

    const goToRegister = () => {
        navigate('/register');
    };

    return (
        <div className="login-page">
            <div className="login-container">
                <h2>Login</h2>
                {errorMessage && <p className="error">{errorMessage}</p>}
                <form className="login-form" onSubmit={handleLogin}>
                    <div>
                        <label htmlFor="username">Username:</label>
                        <input
                            type="text"
                            id="username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                    </div>
                    <div>
                        <label htmlFor="password">Password:</label>
                        <input
                            type="password"
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                    <ImageButton image={keyholeImage} hoverImage={keyholeImage} onClick={handleLogin} />
                </form>
                <button className={"text-button"} onClick={goToRegister}>Not registered yet? Sign up</button>
            </div>
        </div>
    );
};

export default Login;
