import { Navigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import authService from "../../services/authService";


const PrivateRoute = ({ children }) => {
    const [isValidated, setIsValidated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const checkAuth = async () => {
            const isValid = await authService.validateToken();
            setIsValidated(isValid);
            setIsLoading(false);
        };

        checkAuth();
    }, []);

    if (isLoading) {
        return <div>Loading...</div>; // Or any loading spinner
    }

    if (!isValidated) {
        return <Navigate to="/login" />;
    }

    return children;
};

export default PrivateRoute;
