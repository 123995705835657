import React, { useEffect, useRef } from 'react';
import './AudioProgressBar.css';

const AudioProgressBar = ({ audio, isLoading, onComplete, numWords }) => {
    const loadedRef = useRef(null);
    const positionRef = useRef(null);
    const durationEstimate = numWords * 0.82;

    useEffect(() => {
        if (!audio) return;

        const updateProgress = () => {
            const duration = audio.duration === Infinity ? durationEstimate : audio.duration;
            const currentTime = audio.currentTime;
            const progress = (currentTime / duration) * 100;

            if (progress >= 100) {
                onComplete();
            }

            positionRef.current.style.width = `${progress}%`;
        };

        const updateBuffer = () => {
            const duration = audio.duration === Infinity ? durationEstimate : audio.duration;
            let buffered = 0;
            for (let i = 0; i < audio.buffered.length; i++) {
                buffered += audio.buffered.end(i) - audio.buffered.start(i);
            }
            const progress = (buffered / duration) * 100;

            loadedRef.current.style.width = `${progress}%`;
        };

        audio.addEventListener('timeupdate', updateProgress);
        //audio.addEventListener('progress', updateBuffer);

        // Call updateBuffer every 500 milliseconds
        const intervalId = setInterval(updateBuffer, 500);

        return () => {
            audio.removeEventListener('timeupdate', updateProgress);
            //audio.removeEventListener('progress', updateBuffer);
            // Call updateBuffer every 500 milliseconds
            clearInterval(intervalId);
        };
    }, [audio]);

    if (isLoading) return null;

    return (
        <div className="audio-progress-bar">
            <div ref={loadedRef} className="loaded"></div>
            <div ref={positionRef} className="position"></div>
        </div>
    );
};

export default AudioProgressBar;
