import React from 'react';
import ReactMarkdown from 'react-markdown';

import './Message.css';


const Message = ({ message, audioProgress, sender }) => {
    return (
        <div className={`message ${message.role} ${message.role === 'assistant' ? sender: ''}`}>
            <div className={"message-text"}>
                <ReactMarkdown>{message.content[0].text.value}</ReactMarkdown>
            </div>
            {audioProgress}
        </div>
    );
};

export default Message;