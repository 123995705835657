import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import authService from '../../services/authService';
import ImageButton from "../../components/ImageButton";
import './Register.css';

import registerImage from "../../assets/sorting-hat-button-100x100.webp";

const Register = () => {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();

    const handleRegister = async (e) => {
        e.preventDefault();
        setErrorMessage('');

        try {
            const response = await authService.register(username, email, password);
            if (response.data) {
                navigate('/login'); // Redirect to login after successful registration
            }
        } catch (error) {
            // TODO - specify error type
            setErrorMessage('Error during registration. Please try again.');
        }
    };

    const goToLogin = () => {
        navigate('/login');
    };

    return (
        <div className="register-page">
            <div className="register-container">
                <h2>Register</h2>
                {errorMessage && <p className="error">{errorMessage}</p>}
                <form onSubmit={handleRegister} className="register-form">
                    <div>
                        <label htmlFor="username">Username:</label>
                        <input
                            type="text"
                            id="username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="email">Email:</label>
                        <input
                            type="email"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="password">Password:</label>
                        <input
                            type="password"
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </div>
                    <ImageButton image={registerImage} hoverImage={registerImage} onClick={handleRegister}/>
                </form>
                <button className={"text-button"} onClick={goToLogin}>Already registered? Log in</button>
            </div>
        </div>
    );
};

export default Register;
